import SvgIcon from "src/components/shared/SvgIcon";

const IconGoogleDocs = (props: any) => {
  const size = props?.size || 12;
  return (
    <SvgIcon>
      <svg
        width={size}
        height={size}
        viewBox="0 0 73 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1:149)">
          <mask
            id="mask0_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1:149)">
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L56.4904 15.9091L45.1923 0Z"
              fill="#4285F4"
            />
          </g>
          <mask
            id="mask1_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_1:149)">
            <path
              d="M47.1751 25.2784L72.3077 50.5511V27.2727L47.1751 25.2784Z"
              fill="url(#paint0_linear_1:149)"
            />
          </g>
          <mask
            id="mask2_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_1:149)">
            <path
              d="M18.0769 72.7273H54.2308V68.1818H18.0769V72.7273ZM18.0769 81.8182H45.1923V77.2727H18.0769V81.8182ZM18.0769 50V54.5455H54.2308V50H18.0769ZM18.0769 63.6364H54.2308V59.0909H18.0769V63.6364Z"
              fill="#F1F1F1"
            />
          </g>
          <mask
            id="mask3_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_1:149)">
            <path
              d="M45.1923 0V20.4545C45.1923 24.2216 48.2258 27.2727 51.9712 27.2727H72.3077L45.1923 0Z"
              fill="#A1C2FA"
            />
          </g>
          <mask
            id="mask4_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_1:149)">
            <path
              d="M6.77885 0C3.05048 0 0 3.06818 0 6.81818V7.38636C0 3.63636 3.05048 0.568182 6.77885 0.568182H45.1923V0H6.77885Z"
              fill="white"
              fillOpacity="0.2"
            />
          </g>
          <mask
            id="mask5_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_1:149)">
            <path
              d="M65.5288 99.4318H6.77885C3.05048 99.4318 0 96.3636 0 92.6136V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V92.6136C72.3077 96.3636 69.2572 99.4318 65.5288 99.4318Z"
              fill="#1A237E"
              fillOpacity="0.2"
            />
          </g>
          <mask
            id="mask6_1:149"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="73"
            height="100"
          >
            <path
              d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_1:149)">
            <path
              d="M51.9712 27.2727C48.2258 27.2727 45.1923 24.2216 45.1923 20.4545V21.0227C45.1923 24.7898 48.2258 27.8409 51.9712 27.8409H72.3077V27.2727H51.9712Z"
              fill="#1A237E"
              fillOpacity="0.1"
            />
          </g>
          <path
            d="M45.1923 0H6.77885C3.05048 0 0 3.06818 0 6.81818V93.1818C0 96.9318 3.05048 100 6.77885 100H65.5288C69.2572 100 72.3077 96.9318 72.3077 93.1818V27.2727L45.1923 0Z"
            fill="url(#paint1_radial_1:149)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1:149"
            x1="59.7428"
            y1="27.4484"
            x2="59.7428"
            y2="50.5547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1A237E" stopOpacity="0.2" />
            <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
          </linearGradient>
          <radialGradient
            id="paint1_radial_1:149"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(2.29074 1.9765) scale(116.595)"
          >
            <stop stopColor="white" stopOpacity="0.1" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <clipPath id="clip0_1:149">
            <rect width="72.3077" height="100" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default IconGoogleDocs;
