import SvgIcon from "src/components/shared/SvgIcon";

const IconBooks = (props: any) => {
  const size = props.size || 16;
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={size}
        height={size}
      >
        /* --!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
        License - https://fontawesome.com/license (Commercial License) Copyright
        2024 Fonticons, Inc. */
        <path d="M128 416l0 48c0 8.8-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16l0-48 96 0zm-16 96c12.3 0 23.5-4.6 32-12.2c8.5 7.6 19.7 12.2 32 12.2l64 0c26.5 0 48-21.5 48-48l0-48 0-16 0-16 0-190.2 51.4 198.1 4 15.5 18 69.2c6.6 25.5 32 40.6 56.7 33.8l59.6-16.5c24.7-6.8 39.3-33 32.7-58.5l-13.9-53.7-4-15.5-63.9-246-4-15.5-18-69.2C400 9.9 374.6-5.2 349.9 1.6L290.3 18.1c-3.5 1-6.8 2.3-9.9 4C271.9 8.8 257 0 240 0L176 0c-12.3 0-23.5 4.6-32 12.2C135.5 4.6 124.3 0 112 0L48 0C21.5 0 0 21.5 0 48L0 96l0 16 0 16L0 384l0 16 0 16 0 48c0 26.5 21.5 48 48 48l64 0zM288 64.8l0-1.3c.3-7.2 5.1-13 10.8-14.6l59.6-16.5c6.6-1.8 14.8 2 17.2 11l14.1 54.2-87.3 24.2L288.6 68.6c-.3-1.3-.5-2.6-.6-3.8zm-32-.6c0 0 0 0 0 0L256 96l-96 0 0-48c0-8.8 7.2-16 16-16l64 0c8.8 0 16 7.2 16 16l0 16.2zM176 480c-8.8 0-16-7.2-16-16l0-48 96 0 0 48c0 8.8-7.2 16-16 16l-64 0zM128 128l0 256-96 0 0-256 96 0zm0-80l0 48L32 96l0-48c0-8.8 7.2-16 16-16l64 0c8.8 0 16 7.2 16 16zM256 384l-96 0 0-256 96 0 0 256zM397.7 128.7l59.8 230.5-87.3 24.2L310.4 152.8l87.3-24.2zm67.9 261.5l13.8 53.2c2.4 9.4-3.2 17.7-10.3 19.6l-59.6 16.5c-6.6 1.8-14.8-2-17.2-11l-14.1-54.2 87.3-24.2z" />
      </svg>
    </SvgIcon>
  );
};

export default IconBooks;
